import React, { useState, Fragment } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import oceanicNext from 'prism-react-renderer/themes/oceanicNext';

import { ReQuartzCron, Tab } from '@sbzen/re-cron';

import { LangProps } from './../../../../shared/lang-props.type';
import { Preview } from './../preview';
import { snippet } from './constants';
import { i18n } from './i18n';

export const YearsExample = ({ lang }: LangProps) => {
  const localization = i18n[lang];
  const years = Array(100).fill(0).map((_, i) => 2020 + i);
  const [yearFrom, setYearFrom] = useState<number|undefined>(undefined);
  const [yearTo, setYearTo] = useState<number|undefined>(undefined);

  return (
    <Fragment>
      <h2 className="doc-subtitle mt-4">
        {localization.title}
      </h2>
      <p>
        {localization.p1} <code>renderYearsFrom</code> {localization.p2} <code>renderYearsTo</code> {localization.p3}
        <br/>
        <code>renderYearsFrom</code> {localization.p4} <code>renderYearsTo</code> {localization.p5}
      </p>
      <p>
        {localization.p6}
      </p>

      <Preview lang={lang}>
        <div data-id="demo">
          <div className="form-inline border rounded p-2 mb-3">
            <label htmlFor="from">From:</label>

            <select
              id="from"
              className="form-control ml-2 mr-2"
              value={yearFrom}
              onChange={(v) => setYearFrom(!!v.target.value ? +v.target.value : undefined)}>
              <option>No value</option>
              {years.map(year => (
                <option
                  key={year}
                  value={year}>
                  {year}
                </option>
              ))}
            </select>

            <label htmlFor="to">To:</label>
            <select
              id="to"
              className="form-control ml-2"
              value={yearTo}
              onChange={(v) => setYearTo(!!v.target.value ? +v.target.value : undefined)}>
              <option>No value</option>
              {years.map(year => (
                <option
                  key={year}
                  value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className="demo">
            <ReQuartzCron
              activeTab={Tab.YEAR}
              renderYearsFrom={yearFrom}
              renderYearsTo={yearTo}/>
          </div>
        </div>

        <div data-id="code">
          <Highlight
            {...defaultProps}
            theme={oceanicNext}
            code={snippet}
            language="tsx">

            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
                </div>
              ))}
              </pre>
            )}
          </Highlight>
        </div>
      </Preview>
    </Fragment>
  );
};
