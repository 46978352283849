import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Highlight, { defaultProps } from 'prism-react-renderer';
import oceanicNext from 'prism-react-renderer/themes/oceanicNext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReQuartzCron } from '@sbzen/re-cron';

import trackingHook from './../../shared/tracking-hook';
import { Meta } from './../../shared/meta';
import { Header } from './../../shared/header/header';
import { LangProps } from './../../shared/lang-props.type';
import { features, example } from './constants';
import { i18n } from './i18n';

import logo from './logo.png';
import ua from './../../shared/ua.svg';
import './styles.scss';

export const Home = ({ lang }: LangProps) => {
  trackingHook();

  const [cronValue, setCronValue] = useState('0 40 7 ? * MON-FRI *');
  const localization = i18n[lang];

  return (
    <div className="home">
      <Meta
        lang={lang}
        prependTitle={false}
        url=""
        title="React Cron widget"/>

      <div className="help">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.org/ukraine-relief/">

          <span>Support Ukraine</span>
          <img
            src={ua}
            width={26}
            height={26}
            alt="Ukraine"/>
          Help Provide Humanitarian Aid to Ukraine.
        </a>
      </div>

      <Header hideBar={true} lang={lang}></Header>

      <div className="short py-5 px-2 text-center">
        <div className="d-flex flex-column flex-md-row justify-content-center">
          <div className="pr-4 order-1 order-md-0">
            <h1>React Cron Widget</h1>
            Quartz / Unix Cron expressions

            <div className="pt-4 text-center">
              <Link
                className="btn btn-lg btn-light"
                to="doc">
                {localization.getStarted}
              </Link>
            </div>
          </div>

          <div>
            <img
              className="logo"
              src={logo}
              width={150}
              height={150}
              alt="React Cron">
            </img>
          </div>
        </div>
      </div>

      <div className="shadow pt-4 pb-4 mb-5">
        <div className="container">
          <div className="row">
            {features(lang).map((feature, i) => {
              return (
                <div
                  key={i}
                  className={'col-sm-4 d-flex pt-3 justify-content-center ' + (i !== 0 ? 'border-left' : '')}>

                  <div className="d-flex feature flex-column align-items-center flex-lg-row align-items-lg-start">
                    <div className="icon text-center pr-3">
                      <FontAwesomeIcon icon={feature.icon}/>
                    </div>
                    <div className="d-flex flex-column text-center text-lg-left">
                      <h2>{feature.title}</h2>
                      <p>{feature.desc}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="container pt-1 mb-5">
        <div className="border text-center rounded p-3 p-md-5">
          <h2>{localization.desc1}</h2>
          <p className="pt-2 pb-3 border-bottom">
            {localization.desc10}
            <br/>
            {localization.desc11}
          </p>

          <p className="pt-2">
          {localization.desc2}
          </p>

          <p>
            {localization.desc3}
            <br/>

            <a
              href="https://www.npmjs.com/package/@sbzen/re-cron"
              className="mr-2"
              target="_blank"
              rel="noopener noreferrer">

              <img
                src="https://camo.githubusercontent.com/5f54dd9a03e1adc0152630aa63e24d7aa63090b63fe33a8fd9bb4286aa9ca20c/68747470733a2f2f62616467652e667572792e696f2f6a732f25343073627a656e25324672652d63726f6e2e737667"
                alt="React cron npm"
                width="126px"
                height="20px"/>
            </a>

            <a
              href="https://www.npmjs.com/package/@sbzen/re-cron"
              target="_blank"
              rel="noopener noreferrer">

              <img
                src="https://camo.githubusercontent.com/600c31b06b70ad5edb6d29fa2767a26bbfad93bdcae3cf16b109c49e408851f9/68747470733a2f2f696d672e736869656c64732e696f2f6e706d2f646d2f25343073627a656e25324672652d63726f6e2e737667"
                alt="React cron npm downloads"
                width="140px"
                height="20px"/>
            </a>

            <br/>
            <code className="mt-3 py-2 px-3 d-inline-block rounded bg-light border border-opacity-10">
              npm i @sbzen/re-cron
            </code>

            <code className="mt-3 ml-3 py-2 px-3 d-inline-block rounded bg-light border border-opacity-10">
              yarn add @sbzen/re-cron
            </code>
          </p>
        </div>

        <div className="row mt-5">
          <div className="col-12 text-center">
            <h2>Quartz cron builder</h2>
            <p className="pt-2">
              {localization.quartz.desc1}
              <br/>
              {localization.quartz.desc2}
              <br/>
              {localization.quartz.desc3}
            </p>

            <div className="table-responsive">
              <table className="table mw-100 w-100">
                <thead>
                  <tr>
                    <th>{localization.common.seconds}</th>
                    <th>{localization.common.minutes}</th>
                    <th>{localization.common.hours}</th>
                    <th>{localization.common.dayOfMonth}</th>
                    <th>{localization.common.month}</th>
                    <th>{localization.common.dayOfWeek}</th>
                    <th>{localization.common.year}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>0</td>
                    <td>40</td>
                    <td>7</td>
                    <td>?</td>
                    <td>*</td>
                    <td>MON-FRI</td>
                    <td>*</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-md-5">
            <Highlight
              {...defaultProps}
              theme={oceanicNext}
              code={example}
              language="tsx">

              {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <pre className={className + ' rounded'} style={style}>
                  {tokens.map((line, i) => (
                    <div {...getLineProps({ line, key: i })}>
                    {line.map((token, key) => (
                      <span {...getTokenProps({ token, key })} />
                    ))}
                    </div>
                  ))}
                </pre>
              )}
            </Highlight>
          </div>

          <div className="col-md-7">
            <input
              className="form-control mb-4"
              readOnly
              value={cronValue} />

            <ReQuartzCron
              value={cronValue}
              onChange={setCronValue} />
          </div>
        </div>
      </div>

      <div className="footer p-3 text-center">
        © 2019-{new Date().getFullYear()} {localization.license}
      </div>
    </div>
  );
}
