export const snippetScss = `// styles.scss
.my-vertical-cron .c-host {
  display: flex;
  flex-direction: row;

  .c-tabs {
    flex-direction: column;
    border: 0;
    border-right: 1px solid #ccc;

    .c-tab {
      text-align: left;
      border: 0;
      border-radius: 0;
    }
  }

  .c-tab-content {
    padding-top: .5rem;
    padding-left: 1rem;
  }
}`;

export const snippetTsx =
`// App.tsx
import React from 'react';
import { ReQuartzCron, ReUnixCron } from '@sbzen/re-cron';

import './styles.scss';

const App = () => (
  <div className="my-vertical-cron">
    <ReQuartzCron/>
    {/* or <ReUnixCron/>*/}
  </div>
);
export default App;`;
