export const i18n = {
  en: {
    getStarted: 'Get Started',
    desc: {
      p1: 'This is an open source project that builds a cron builder component for React applications.',
      p2: 'It supports',
      p3: 'and Unix cron string formats',
      p4: 'for both input and output.',
      p5: 'Inspired by this',
      p6: 'non-react',
      p7: 'implementation.'
    },
    installation: {
      title: 'Installation',
      desc: 'You can use either the npm or yarn command-line tool to install packages.'
    },
    add: {
      title: 'Display the cron component',
      desc: 'Import and add the cron component into your jsx/tsx.'
    },
    result: {
      title: 'UI widget',
      desc: 'As a result you will have this widget'
    }
  },
  ua: {
    getStarted: 'Початок роботи',
    desc: {
      p1: 'Це проєкт з відкритим кодом що створює cron компонент для React додатків.',
      p2: 'Здатний обробляти ',
      p3: 'та Unix cron формати',
      p4: 'як на вхід так і на вихід.',
      p5: 'Натхненний цією',
      p6: 'не react',
      p7: 'реалізацією.'
    },
    installation: {
      title: 'Встановлення',
      desc: 'Ти можеш додати цю бібліотеку за допомогою npm або yarn пакетними менеджерами.'
    },
    add: {
      title: 'Додай компонент до проєкту',
      desc: 'Імпортуй та додай компонент cron до свого jsx/tsx.'
    },
    result: {
      title: 'UI віджет',
      desc: 'Як результат ти отримаєш цей віджет на екрані.'
    }
  }
};
