export const i18n = {
  en: {
    title: 'Active tab',
    desc1: 'Use',
    desc2: 'prop to manually activate tab you want.',
    desc3: 'prop allows you to listen tabs activation',
    unix: 'Unix cron supports next tabs:'
  },
  ua: {
    title: 'Активна вкладка',
    desc1: 'Використовуй',
    desc2: 'prop щоб вручну активувати потрібну вкладку.',
    desc3: 'prop дозволяє прослуховувати активацію вкладок',
    unix: 'Unix cron працює тільки з наступними вкладками:'
  }
};
