export const i18n = {
  en: {
    title: 'Localization',
    p1: 'We have default localization object that contains all visible texts.',
    p2: 'If you need to change some text field or select option use',
    p3: 'prop to pass your version.',
    p4: 'Your localization version will be deeply merged with the default one, allowing you to override only what you want.',
    allLocalization: {
      title: 'All localization properties',
      p: 'Use this manifest for full localization.'
    }
  },
  ua: {
    title: 'Локалізація',
    p1: 'У нас є об’єкт локалізації який містить усі видимі тексти та використовується замовчуванням.',
    p2: 'Якщо тобі потрібно змінити якесь текстове поле або опцію вибору використовуй',
    p3: 'prop щоб передати свою версію перекладу.',
    p4: 'Твоя версія перекладу буде злита з версією замовчуванням що дозволяє перетирати тільки ті значення які тобі потрібні.',
    allLocalization: {
      title: 'Всі ключі локалізації',
      p: 'Використовуй цей маніфест для повної локалізації.'
    }
  }
};
