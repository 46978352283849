import React, { useState, Fragment } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import oceanicNext from 'prism-react-renderer/themes/oceanicNext';

import { ReUnixCron } from '@sbzen/re-cron';

import { LangProps } from './../../../../shared/lang-props.type';
import { Preview } from './../preview';
import { snippet } from './constants';
import { i18n } from './i18n';

export const UnixExample = ({ lang }: LangProps) => {
  const [value, setValue] = useState('5 0 * 1,4 *');
  const localization = i18n[lang];

  return (
    <Fragment>
      <h2 className="doc-subtitle mt-4">{localization.title}</h2>
      <p>
        {localization.desc}
      </p>

      <Preview lang={lang}>
        <div data-id="demo">
          <input
            className="form-control mb-3"
            readOnly
            value={value}/>

          <div className="demo">
            <ReUnixCron
              value={value}
              onChange={setValue}/>
          </div>
        </div>

        <div data-id="code">
          <Highlight
            {...defaultProps}
            theme={oceanicNext}
            code={snippet}
            language="tsx">

            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
                </div>
              ))}
              </pre>
            )}
          </Highlight>
        </div>
      </Preview>
    </Fragment>
  );
};
