import React, { Fragment } from 'react';
import { HashLink } from 'react-router-hash-link';

import { Meta } from './../../../shared/meta';
import { LangProps } from './../../../shared/lang-props.type';
import { HighlightElementsExample, highlightElementsI18n } from './../shared/highlight-elements';
import { DisabledStateExample, disabledStateI18n } from './../shared/disabled-state';
import { VerticalTabsExample, verticalTabsI18n } from './../shared/vertical-tabs';
import { LocalizationExample, localizationI18n } from './../shared/localization';
import { FormControlExample, formControlI18n } from './../shared/form-control';
import { ActiveTabExample, activeTabI18n } from './../shared/active-tab';
import { HideTabsExample, hideTabsI18n } from './../shared/hide-tabs';
import { YearsExample, yearsI18n } from './../shared/years';
import { TabsExample, tabsI18n } from './../shared/tabs';
import { UnixExample, unixI18n } from './../shared/unix';
import { i18n } from './i18n';

export const UsageDemo = ({ lang }: LangProps) => {
  const localization = i18n[lang];
  const { title: formControlTitle } = formControlI18n[lang];
  const { title: activeTabTitle } = activeTabI18n[lang];
  const { title: disabledStateTitle } = disabledStateI18n[lang];
  const { title: hideTabsTitle } = hideTabsI18n[lang];
  const { title: tabsTitle } = tabsI18n[lang];
  const { title: yearsTitle } = yearsI18n[lang];
  const { title: highlightElementsTitle } = highlightElementsI18n[lang];
  const { title: verticalTabsTitle } = verticalTabsI18n[lang];
  const { title: unixTitle } = unixI18n[lang];
  const { title: localizationTitle } = localizationI18n[lang];

  return (
    <Fragment>
      <Meta
        lang={lang}
        url="/doc/usage-demo"
        title={localization.title}/>

      <div className="content-section border-bottom">
        <h1 className="doc-title pb-3">
          {localization.title}
        </h1>

        <ul className="mb-0">
          <li>
            <HashLink smooth to="#unix">
              {unixTitle}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#form-control">
              {formControlTitle}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#active-tab">
              {activeTabTitle}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#disabled-state">
              {disabledStateTitle}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#years">
              {yearsTitle}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#tabs">
              {tabsTitle}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#hide-tabs">
              {hideTabsTitle}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#localization">
              {localizationTitle}
            </HashLink>
          </li>
          <li>
            {localization.styling}

            <ul>
              <li>
                <HashLink smooth to="#highlight-elements">
                  {highlightElementsTitle}
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="#vertical-tabs">
                  {verticalTabsTitle}
                </HashLink>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div
        id="unix"
        className="content-section border-bottom">

        <UnixExample lang={lang}/>
      </div>

      <div
        id="form-control"
        className="content-section border-bottom">

        <FormControlExample lang={lang}/>
      </div>

      <div
        id="active-tab"
        className="content-section border-bottom">

        <ActiveTabExample lang={lang}/>
      </div>

      <div
        id="disabled-state"
        className="content-section border-bottom">

        <DisabledStateExample lang={lang}/>
      </div>

      <div
        id="years"
        className="content-section border-bottom">

        <YearsExample lang={lang}/>
      </div>

      <div
        id="tabs"
        className="content-section border-bottom">

        <TabsExample lang={lang}/>
      </div>

      <div
        id="localization"
        className="content-section border-bottom">

        <LocalizationExample lang={lang}/>
      </div>

      <div
        id="hide-tabs"
        className="content-section border-bottom">

        <HideTabsExample lang={lang}/>
      </div>

      <div
        id="highlight-elements"
        className="content-section border-bottom">

        <HighlightElementsExample lang={lang}/>
      </div>

      <div
        id="vertical-tabs"
        className="content-section">

        <VerticalTabsExample lang={lang}/>
      </div>
    </Fragment>
  );
};
