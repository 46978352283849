import React, { useState, Fragment } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import oceanicNext from 'prism-react-renderer/themes/oceanicNext';

import { ReQuartzCron, Tab } from '@sbzen/re-cron';

import { LangProps } from './../../../../shared/lang-props.type';
import { Preview } from './../preview';
import { snippet } from './constants';
import { i18n } from './i18n';

export const ActiveTabExample = ({ lang }: LangProps) => {
  const [tabs] = useState([Tab.SECONDS, Tab.MINUTES, Tab.HOURS, Tab.DAY, Tab.MONTH, Tab.YEAR]);
  const [activeTab, setActiveTab] = useState(Tab.MINUTES);
  const value = '0,1,2 2/4 6/2 ? 2-7 SUN,MON 2019/1';
  const localization = i18n[lang];

  return (
    <Fragment>
      <h2 className="doc-subtitle mt-4">{localization.title}</h2>
      <p>
        {localization.desc1} <code>activeTab</code> {localization.desc2}
        <br/>
        <code>onTabChange</code> {localization.desc3}.
        <br/>
        {localization.unix} <code>Tab.MINUTES</code>, <code>Tab.HOURS</code>, <code>Tab.MONTH</code>, <code>Tab.DAY</code>.
      </p>

      <Preview lang={lang}>
        <div data-id="demo">
          <div className="border rounded p-3 mb-4">
            <b className="mr-2">Tabs:</b>
            {tabs.map(tab => (
              <div
                key={tab}
                className="form-check form-check-inline">

                <input
                  type="radio"
                  className="form-check-input"
                  name="activeTab"
                  checked={activeTab === tab}
                  onChange={() => setActiveTab(tab)}
                  id={`active-tab-${tab}`}/>

                <label
                  className="form-check-label"
                  htmlFor={`active-tab-${tab}`}>
                  {tab.toLowerCase()}
                </label>
              </div>
            ))}
          </div>

          <div className="demo">
            <ReQuartzCron
              activeTab={activeTab}
              onTabChange={setActiveTab}
              value={value} />
          </div>
        </div>

        <div data-id="code">
          <Highlight
            {...defaultProps}
            theme={oceanicNext}
            code={snippet}
            language="tsx">

            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
                </div>
              ))}
              </pre>
            )}
          </Highlight>
        </div>
      </Preview>
    </Fragment>
  );
};
