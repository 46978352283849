import React from 'react';
import { NavLink } from 'react-router-dom';

import { LangProps } from './../../shared/lang-props.type';
import { i18n } from './nav.i18n';
import './nav.scss';

export const Nav = ({ lang }: LangProps) => {
  const activeClasses = 'nav-link active';
  const notActiveClases = 'nav-link';
  const localization = i18n[lang];

  return (
    <nav className="navigation nav flex-column h-100 pt-3">
      <NavLink
        className={({ isActive }) => isActive ? activeClasses : notActiveClases}
        to="get-started">

        {localization.getStarted}
      </NavLink>
      <NavLink
        className={({ isActive }) => isActive ? activeClasses : notActiveClases}
        to="usage-demo">
        {localization.demo}
      </NavLink>
      <NavLink
        className={({ isActive }) => isActive ? activeClasses : notActiveClases}
        to="cron-format">
        {localization.format}
      </NavLink>
      <NavLink
        className={({ isActive }) => isActive ? activeClasses : notActiveClases}
        to="customization">
        {localization.customization}
      </NavLink>
      <ul>
        <li>
          <NavLink
            className={({ isActive }) => isActive ? activeClasses : notActiveClases}
            to="customization/visibility-props">
            {localization.visibilityProps}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => isActive ? activeClasses : notActiveClases}
            to="customization/css-styling">
            {localization.cssStyling}
          </NavLink>
        </li>
      </ul>
      <NavLink
        className={({ isActive }) => isActive ? activeClasses : notActiveClases}
        to="localization">
        {localization.localization}
      </NavLink>
      <NavLink
        className={({ isActive }) => isActive ? activeClasses : notActiveClases}
        to="api-reference">
        {localization.apiReference}
      </NavLink>
      <NavLink
        className={({ isActive }) => isActive ? activeClasses : notActiveClases}
        to="compatibility">
        {localization.compatibility}
      </NavLink>
    </nav>
  );
};
