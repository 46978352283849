import { faHandPeace } from '@fortawesome/free-regular-svg-icons';
import { faBrush } from '@fortawesome/free-solid-svg-icons';
import { faNpm } from '@fortawesome/free-brands-svg-icons';

import { LangProps } from './../../shared/lang-props.type';
import { i18n } from './i18n';

export const features = (lang: LangProps['lang']) => [
  {
    icon: faHandPeace,
    title: i18n[lang].features.native.title,
    desc: i18n[lang].features.native.desc
  },
  {
    icon: faBrush,
    title: i18n[lang].features.customization.title,
    desc: i18n[lang].features.customization.desc
  },
  {
    icon: faNpm,
    title: i18n[lang].features.openSource.title,
    desc: i18n[lang].features.openSource.desc
  }
];

export const example =
`import React, { Fragment, useState } from 'react';
import { ReQuartzCron } from '@sbzen/re-cron';

const App = () => {
  const [value, setValue] = useState('2,0,4,3,1 0/1 3/2 ? * 4/5 *');

  return (
    <Fragment>
      <input
        className="form-control mb-4"
        readOnly
        value={value}/>

      <ReQuartzCron
        value={value}
        onChange={setValue}/>
    </Fragment>
  );
};
export default App;`;
