export const i18n = {
  en: {
    title: 'Form control',
    desc: 'The cron component works as a simple form control.',
    value: 'Cron Value'
  },
  ua: {
    title: 'Елемент форми',
    desc: 'Компонент працює як звичайний елемент форми.',
    value: 'Cron результат'
  }
};
