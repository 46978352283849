export const i18n = {
  en: {
    title: 'Years managing',
    p1: 'Use',
    p2: 'and ',
    p3: 'props to specify start and end years.',
    p4: 'takes 2019 and',
    p5: 'takes 2098 as values by default.',
    p6: 'Unix cron doesn\'t support years.'
  },
  ua: {
    title: 'Керування роками',
    p1: 'Використовуй',
    p2: 'та ',
    p3: 'props щоб встановити рік початку та кінцевий рік.',
    p4: 'візьме 2019 та',
    p5: 'візьме 2098 як значення по замовчуванню.',
    p6: 'Unix cron не працює з роками.'
  }
};
