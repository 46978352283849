import React, { useState, Fragment } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import oceanicNext from 'prism-react-renderer/themes/oceanicNext';

import { ReQuartzCron, Tab } from '@sbzen/re-cron';

import { LangProps } from './../../../../shared/lang-props.type';
import { Preview } from './../preview';
import { snippet } from './constants';
import { i18n } from './i18n';

export const TabsExample = ({ lang }: LangProps) => {
  const [tabsManagingTabs, setTabsManagingTabs] = useState([Tab.MINUTES, Tab.HOURS]);
  const localization = i18n[lang];

  const toggleTab = (tab: Tab) => {
    if (isVisinleTab(tab)) {
      const nextTabs = tabsManagingTabs.filter(t => t !== tab);
      setTabsManagingTabs(nextTabs);
      return;
    }
    setTabsManagingTabs([
      ...tabsManagingTabs,
      tab
    ]);
  };

  const isVisinleTab = (tab: Tab) => {
    return tabsManagingTabs.includes(tab);
  };

  return (
    <Fragment>
      <h2 className="doc-subtitle mt-4">
        {localization.title}
      </h2>
      <p>
        {localization.p1} <code>tabs</code> {localization.p2}
        <br/>
        {localization.p3}
      </p>

      <p>
        <b>{localization.availableQuartzValues}</b>
        <br/>
        <code>Tab.SECONDS, Tab.MINUTES, Tab.HOURS, Tab.DAY, Tab.MONTH, Tab.YEAR</code>
      </p>

      <p>
        <b>{localization.availableUnixValues}</b>
        <br/>
        <code>Tab.MINUTES, Tab.HOURS, Tab.DAY, Tab.MONTH</code>
      </p>

      <Preview lang={lang}>
        <div data-id="demo">
          <div className="border rounded p-2 mb-3">
            <b className="mr-2">{localization.visibleTabs}</b>
            {[Tab.SECONDS, Tab.MINUTES, Tab.HOURS, Tab.DAY, Tab.YEAR].map(tab => (
              <div
                key={tab}
                className="form-check form-check-inline">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={isVisinleTab(tab)}
                  onChange={() => toggleTab(tab)}
                  id={`tabs-managing-${tab}`}/>

                <label
                  className="form-check-label"
                  htmlFor={`tabs-managing-${tab}`}>
                  {tab.toLowerCase()}
                </label>
              </div>
            ))}
          </div>

          <div className="demo">
            <ReQuartzCron tabs={tabsManagingTabs}/>
          </div>
        </div>

        <div data-id="code">
          <Highlight
            {...defaultProps}
            theme={oceanicNext}
            code={snippet}
            language="tsx">

            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
                </div>
              ))}
              </pre>
            )}
          </Highlight>
        </div>
      </Preview>
    </Fragment>
  );
};
