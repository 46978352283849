export const snippet =
`import React, { useState } from 'react';
import { Tab, ReQuartzCron, ReUnixCron } from '@sbzen/re-cron';

const App = () => {
  const [activeTab, setActiveTab] = useState(Tab.MINUTES);

  return (
    <ReQuartzCron activeTab={activeTab} onTabChange={setActiveTab}/>
    // or <ReUnixCron activeTab={activeTab} onTabChange={setActiveTab}/>
  );
};
export default App;`;
