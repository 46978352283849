import React, { useState } from 'react';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LangProps } from './../../../../shared/lang-props.type';
import { i18n } from './i18n';

export const Preview = ({ lang, children }: LangProps & { children: JSX.Element[] }) => {
  const [tab, setTab] = useState('demo');
  const localization = i18n[lang];

  return (
    <div className="border rounded">
      <div className="bg-light p-2">
        <ul className="nav nav-pills justify-content-end">
          <li className="nav-item">
            <button
              className={'nav-link' + (tab === 'demo' ? ' active' : '')}
              type="button"
              onClick={() => setTab('demo')}>

              <FontAwesomeIcon
                className="mr-2"
                icon={faEye}/>

              {localization.demo}
            </button>
          </li>

          <li className="nav-item">
            <button
              className={'nav-link' + (tab === 'code' ? ' active' : '')}
              type="button"
              onClick={() => setTab('code')}>


              <FontAwesomeIcon
                className="mr-2"
                icon={faCode}/>

              {localization.code}
            </button>
          </li>
        </ul>
      </div>

      <div className={tab === 'code' ? '' : 'p-2'}>
        {children.find(({ props }) => props['data-id'] === tab)}
      </div>
    </div>
  );
};
