export const snippet =
`import React from 'react';
import { ReQuartzCron, ReUnixCron, CronLocalization } from '@sbzen/re-cron';

const localization: CronLocalization = {
  tabs: {
    seconds: 'Секунди'
  },
  quartz: {
    second: {
      every: {
        label: 'Кожна секунда'
      },
      increment: {
        label1: 'Кожні',
        label2: 'секунди, починаючи з секунди'
      },
      and: {
        label: 'Конкретна секунда (виберіть одну або кілька)'
      },
      range: {
        label1: 'Щосекунди між секундою',
        label2: 'і секундою'
      }
    }
  }
};

const App = () => (
  <ReQuartzCron localization={localization}/>
  // or <ReUnixCron localization={localization}/>
);
export default App;`;

export const translation = {
  tabs: {
    seconds: 'Секунди'
  },
  quartz: {
    second: {
      every: {
        label: 'Кожна секунда'
      },
      increment: {
        label1: 'Кожні',
        label2: 'секунди, починаючи з секунди',
      },
      and: {
        label: 'Конкретна секунда (виберіть одну або кілька)'
      },
      range: {
        label1: 'Щосекунди між секундою',
        label2: 'і секундою'
      }
    }
  }
};
