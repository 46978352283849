export const i18n = {
  en: {
    getStarted: 'Get Started',
    demo: 'Usage & Demo',
    format: 'Cron Format',
    customization: 'Customization',
    visibilityProps: 'Visibility props',
    cssStyling: 'CSS styling',
    localization: 'Localization',
    apiReference: 'API reference',
    compatibility: 'Compatibility'
  },
  ua: {
    getStarted: 'Початок роботи',
    demo: 'Використання та демо',
    format: 'Cron формат',
    customization: 'Налаштування',
    visibilityProps: 'Параметри відображення',
    cssStyling: 'CSS стилізація',
    localization: 'Локалізація',
    apiReference: 'API документація',
    compatibility: 'Сумісність версій'
  }
};
