import { hydrate, render } from 'react-dom';
import ReactGA from 'react-ga4';
import reportWebVitals from './reportWebVitals';
import App from './app';

import './index.scss';

const snap = navigator.userAgent === 'ReactSnap';
const production = process.env.NODE_ENV === 'production';
const alpha = process.env.REACT_APP_ALPHA;
if (!snap && !alpha && production) {
  ReactGA.initialize('G-HXGLNXNVFG');
}

const rootElement = document.getElementById('root') as HTMLElement;
if (rootElement.hasChildNodes()) {
  hydrate(<App/>, rootElement);
} else {
  render(<App/>, rootElement);
}

reportWebVitals();
