export const i18n = {
  en: {
    getStarted: 'Get Started',
    demo: 'Demo'
  },
  ua: {
    getStarted: 'Початок роботи',
    demo: 'Демо'
  }
};
