export const snippetScss = `// styles.scss
.my-cron [tab-name="SECONDS"] {
  .c-and {
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 5px;

    &-item[item-value="1"],
    &-item[item-value="20"] {
      .c-and-item-check {
        background-color: red;
        border-radius: 5px;
        color: #fff;
        padding-left: 1.5rem;
      }
    }
  }
}`;

export const snippetTsx =
`// App.tsx
import React from 'react';
import { ReQuartzCron, ReUnixCron } from '@sbzen/re-cron';

import './styles.scss';

const App = () => (
  <div className="my-cron">
    <ReQuartzCron/>
    {/* or <ReUnixCron/>*/}
  </div>
);
export default App;`;
