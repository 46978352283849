export const i18n = {
  en: {
    title: 'Whole redesign',
    p1: 'The template uses the bootstrap 4 css classes without any custom styling or overriding.',
    p2: 'To customize the ui by yourself you need to use the',
    p3: 'prop and pass the class prefix.',
    p4: 'The prefix will be used in css classes generating, for example you passed',
    p5: 'as a result the bootstrap class will be transformed from',
    p6: 'to'
  },
  ua: {
    title: 'Повний редизайн',
    p1: 'Компонент використовує bootstrap 4 css classes без будь-яких додаткових стилів або замін.',
    p2: 'Щоб самостійно налаштувати інтерфейс використовуй',
    p3: 'prop та передай css class префікс.',
    p4: 'Префікс буде використаний при генерації css classes, наприклад ти передав',
    p5: 'та як результат bootstrap class буде трансформований з',
    p6: 'в'
  }
};
