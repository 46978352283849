export const i18n = {
  en: {
    title: 'CSS styling',
    p1: 'This is a bootstrap 4 based component, but any time it can be used without any dependencies and will be absolutely unstyled.',
    p2: 'There are two kind of customizations you can do:',
    someCorrections: {
      title: 'Some corrections',
      p1: 'Every html element has specific css class and you can use that to make some ui corrections.',
      p2: 'It will work only if your styles will be added in global',
      p3: 'file.'
    }
  },
  ua: {
    title: 'CSS стилізація',
    p1: 'Цей компонент зроблений на основі bootstrap 4 але в любий час ти можеш прибрати цю залежність та компонент стане повніслю нестилізований.',
    p2: 'Є два типи налаштувань, які можуть бути застосовані:',
    someCorrections: {
      title: 'Деякі корекції',
      p1: 'Кожен елемент html має певний css class, і ти можеш використовувати його для внесення деяких виправлень інтерфейсу.',
      p2: 'Це працюватиме, лише якщо ваші стилі додані в глобальний',
      p3: 'файл.'
    }
  }
};
