export const snippet =
`import React, { useState, Fragment } from 'react';
import { ReQuartzCron, ReUnixCron } from '@sbzen/re-cron';

const App = () => {
  const [disabled, setDisabled] = useState(false);

  return (
    <Fragment>
      <b>Disabled: </b>
      <code>{disabled ? 'true' : 'false'}</code>
      <br/>
      <button
        type="button"
        className="btn btn-sm btn-secondary"
        onClick={() => setDisabled(!disabled)}>
        Toggle state
      </button>

      <ReQuartzCron disabled={disabled}/>
      {/* or <ReUnixCron disabled={disabled}/>*/}
    </Fragment>
  );
};
export default App;`;
