export const i18n = {
  en: {
    title: 'How to translate the cron component?',
    p1: 'Pass localization object to',
    p2: 'prop to have translated texts.',
    p3: 'See this example of the translation of the "Seconds" tab into Ukrainian.'
  },
  ua: {
    title: 'Як перекласти cron компонент?',
    p1: 'Передай об\'єкт перекладу в',
    p2: 'prop щоб перекласти тексти.',
    p3: 'Дивись приклад перекладу вкладки "Seconds" українською мовою.'
  }
};
