export const i18n = {
  en: {
    title: 'Compatibility',
    p1: 'The only two required dependencies are React and cron-core.',
    p2: 'The Bootstrap CSS is optional as you can use this component with your own styling.'
  },
  ua: {
    title: 'Сумісність версій',
    p1: 'Ми маємо дві залежності React та cron-core.',
    p2: 'Bootstrap CSS не обов\'язковим і ти можеш використовувати компонент зі своїми персональними стилями.'
  }
};
