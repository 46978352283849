import React, { Fragment } from 'react';

import { HashLink } from 'react-router-hash-link';

import { Meta } from './../../../shared/meta';
import { LangProps } from './../../../shared/lang-props.type';
import { QuartzExample, quartzI18n } from './../shared/quartz';
import { UnixExample, unixI18n } from './../shared/unix';
import { i18n } from './i18n';

export const CronFormat = ({ lang }: LangProps) => {
  const localization = i18n[lang];
  const { title: quartzTitle } = quartzI18n[lang];
  const { title: unixTitle } = unixI18n[lang];

  return (
    <Fragment>
      <Meta
        lang={lang}
        url="/doc/cron-format"
        title={localization.title}/>

      <div className="content-section border-bottom">
        <h1 className="doc-title">
          {localization.title}
        </h1>
        <p>
          {localization.desc}
        </p>
        <ul>
          <li>
            <HashLink smooth to="#quartz-cron">
              {quartzTitle}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#unix-cron">
              {unixTitle}
            </HashLink>
          </li>
        </ul>
      </div>

      <div
        id="quartz-cron"
        className="content-section border-bottom">

        <QuartzExample lang={lang}/>
      </div>

      <div
        id="unix-cron"
        className="content-section">

        <UnixExample lang={lang}/>
      </div>
    </Fragment>
  );
};
