import React, { Fragment } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import oceanicNext from 'prism-react-renderer/themes/oceanicNext';
import { HashLink } from 'react-router-hash-link';

import { Meta } from './../../../shared/meta';
import { LangProps } from './../../../shared/lang-props.type';
import { LocalizationExample, localizationI18n } from './../shared/localization';
import { fullLocalization } from './constants';
import { i18n } from './i18n';

export const Localization = ({ lang }: LangProps) => {
  const localization = i18n[lang];
  const { title: localizationTitle } = localizationI18n[lang];

  return (
    <Fragment>
      <Meta
        lang={lang}
        url="/doc/localization"
        title={localization.title}/>

      <div className="content-section border-bottom">
        <h1 className="doc-title pb-3">
          {localization.title}
        </h1>

        <p>
          {localization.p1}
        </p>

        <p>
          {localization.p2} <code>localization</code> {localization.p3}
          <br/>
          {localization.p4}
        </p>

        <ul>
          <li>
            <HashLink smooth to="#how-to-translate">
              {localizationTitle}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#all-localization-properties">
              {localization.allLocalization.title}
            </HashLink>
          </li>
        </ul>
      </div>

      <div
        id="how-to-translate"
        className="content-section border-bottom">

        <LocalizationExample lang={lang}/>
      </div>

      <div
        id="all-localization-properties"
        className="content-section">

        <h2 className="h5">
          {localization.allLocalization.title}
        </h2>

        <p>
          {localization.allLocalization.p}
        </p>

        <Highlight
          {...defaultProps}
          theme={oceanicNext}
          code={fullLocalization}
          language="typescript">

          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
              </div>
            ))}
            </pre>
          )}
        </Highlight>
      </div>
    </Fragment>
  );
};
