export const snippet =
`import React, { useState, Fragment } from 'react';
import { ReQuartzCron, ReUnixCron } from '@sbzen/re-cron';

const App = () => {
  const [cronValue, setCronValue] = useState('0,1,2 2/4 6/2 ? 2-7 SUN,MON 2019/1');

  return (
    <Fragment>
      <div className="py-2">
        <b>Cron Value: </b>
        <code>{cronValue}</code>
      </div>

      <ReQuartzCron
        value={cronValue}
        onChange={setCronValue}/>

      {/* or <ReUnixCron
        value={cronValue}
        onChange={setCronValue}/>*/}
    </Fragment>
  );
};
export default App;`;
