import { StrictMode } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { GetStarted } from './containers/doc/get-started';
import { Compatibility } from './containers/doc/compatibility';
import { ApiReference } from './containers/doc/api-reference';
import { CronFormat } from './containers/doc/cron-format';
import { Localization } from './containers/doc/localization';
import { UsageDemo } from './containers/doc/usage-demo';
import { CustomizationVisibilityProps } from './containers/doc/customization/visibility-props';
import { CustomizationCssStyling } from './containers/doc/customization/css-styling';
import { Home } from './containers/home';
import { Doc } from './containers/doc';

export const App = () => (
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/ua'>
          <Route index element={<Home lang="ua"/>}/>
          <Route path="doc" element={<Doc lang="ua"/>}>
            <Route path="" element={<Navigate to="get-started" replace/>}/>
            <Route path="get-started" element={<GetStarted lang="ua"/>}/>
            <Route path="api-reference" element={<ApiReference lang="ua"/>}/>
            <Route path="compatibility" element={<Compatibility lang="ua"/>}/>
            <Route path="cron-format" element={<CronFormat lang="ua"/>}/>
            <Route path="localization" element={<Localization lang="ua"/>}/>
            <Route path="usage-demo" element={<UsageDemo lang="ua"/>}/>
            <Route path="customization">
              <Route path="" element={<Navigate to="visibility-props" replace/>}/>
              <Route path="visibility-props" element={<CustomizationVisibilityProps lang="ua"/>}/>
              <Route path="css-styling" element={<CustomizationCssStyling lang="ua"/>}/>
            </Route>
          </Route>
        </Route>

        <Route path='/'>
          <Route index element={<Home lang="en"/>}/>
          <Route path="doc" element={<Doc lang="en"/>}>
            <Route path="" element={<Navigate to="get-started" replace/>}/>
            <Route path="get-started" element={<GetStarted lang="en"/>}/>
            <Route path="api-reference" element={<ApiReference lang="en"/>}/>
            <Route path="compatibility" element={<Compatibility lang="en"/>}/>
            <Route path="cron-format" element={<CronFormat lang="en"/>}/>
            <Route path="localization" element={<Localization lang="en"/>}/>
            <Route path="usage-demo" element={<UsageDemo lang="en"/>}/>
            <Route path="customization">
              <Route path="" element={<Navigate to="visibility-props" replace/>}/>
              <Route path="visibility-props" element={<CustomizationVisibilityProps lang="en"/>}/>
              <Route path="css-styling" element={<CustomizationCssStyling lang="en"/>}/>
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </StrictMode>
);

export default App;
