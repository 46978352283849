export const i18n = {
  en: {
    title: 'Cron Format',
    desc: 'This library supports quartz and unix cron expressions.'
  },
  ua: {
    title: 'Cron формат',
    desc: 'Ця бібліотека підтримує quartz та unix cron формати.'
  }
};
