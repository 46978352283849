export const snippet =
`import React, { useState, Fragment } from 'react';
import { ReQuartzCron, ReUnixCron } from '@sbzen/re-cron';

const App = () => {
  const [tabsVisibility, setTabsVisibility] = useState(true);

  return (
    <Fragment>
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => setTabsVisibility(!tabsVisibility)}>
        Toggle
      </button>

      <ReQuartzCron hideTabs={tabsVisibility}/>
      {/* or <ReUnixCron hideTabs={tabsVisibility}/>*/}
    </Fragment>
  );
};
export default App;`;
