export const i18n = {
  en: {
    title: 'Unix cron',
    desc: 'There is ReUnixCron component to work with unix cron format.'
  },
  ua: {
    title: 'Unix формат',
    desc: 'Для роботи з unix cron форматом використовуй ReUnixCron компонент.'
  }
};
