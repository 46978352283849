export const i18n = {
  en: {
    title: 'Tabs visibility',
    p1: 'Use',
    p2: 'prop to hide tab buttons.',
    hide: 'Hide tabs',
    show: 'Show tabs'
  },
  ua: {
    title: 'Видимість вкладок',
    p1: 'Використовуй',
    p2: 'prop щоб сховати вкладки.',
    hide: 'Сховати вкладки',
    show: 'Показати вкладки'
  }
};
