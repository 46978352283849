import React from 'react';

import { Meta } from './../../../shared/meta';
import { LangProps } from './../../../shared/lang-props.type';
import { i18n } from './i18n';
import './styles.scss';

export const Compatibility = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="content-section">
      <Meta
        lang={lang}
        url="/doc/compatibility"
        title={localization.title}/>

      <h1 className="doc-title">
        {localization.title}
      </h1>

      <p className="mt-4">
        {localization.p1}
        <br />
        {localization.p2}
      </p>

      <table className="table mt-4">
        <thead>
          <tr>
            <th>Re-Cron</th>
            <th>React</th>
            <th>Bootstrap CSS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0.0.1</td>
            <td>16.x.x</td>
            <td>4.x.x</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
