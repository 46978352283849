import React, { Fragment } from 'react';
import { HashLink } from 'react-router-hash-link';

import { Meta } from './../../../../shared/meta';
import { LangProps } from './../../../../shared/lang-props.type';
import { HighlightElementsExample } from './../../shared/highlight-elements';
import { RedesignExample, redesignI18n } from './../../shared/redesign';
import { VerticalTabsExample } from './../../shared/vertical-tabs';
import { i18n } from './i18n';

export const CustomizationCssStyling = ({ lang }: LangProps) => {
  const localization = i18n[lang];
  const { title: redesignTitle } = redesignI18n[lang];

  return (
    <Fragment>
      <Meta
        lang={lang}
        url="/doc/customization/css-styling"
        title={localization.title}/>

      <div className="content-section border-bottom">
        <h1 className="doc-title">{localization.title}</h1>
        <p>
          {localization.p1}
        </p>

        {localization.p2}

        <ul className="pt-3">
          <li>
            <HashLink smooth to="#some-corrections">
              {localization.someCorrections.title}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#whole-redesign">
              {redesignTitle}
            </HashLink>
          </li>
        </ul>
      </div>

      <div
        id="some-corrections"
        className="content-section border-bottom">

        <h2 className="doc-subtitle mt-4">{localization.someCorrections.title}</h2>
        <p>
          {localization.someCorrections.p1}
          <br />
          {localization.someCorrections.p2} <code>styles.scss</code> {localization.someCorrections.p3}
        </p>
      </div>

      <div className="content-section border-bottom">
        <HighlightElementsExample lang={lang}/>
      </div>

      <div className="content-section border-bottom">
        <VerticalTabsExample lang={lang}/>
      </div>

      <div
        id="whole-redesign"
        className="content-section">

        <RedesignExample lang={lang}/>
      </div>
    </Fragment>
  );
};
