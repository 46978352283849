export const snippet =
`import React from 'react';
import { ReQuartzCron } from '@sbzen/re-cron';

const App = () => (
  <ReQuartzCron
    renderYearsFrom={2020}
    renderYearsTo={2119}/>
);
export default App;`;
