export const i18n = {
  en: {
    demo: 'Demo',
    code: 'Code'
  },
  ua: {
    demo: 'Демо',
    code: 'Код'
  }
};
