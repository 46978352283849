export const snippet =
`import React, { useState } from 'react';
import { ReUnixCron } from '@sbzen/re-cron';

const App = () => {
  const [value, setValue] = useState('5 0 * JAN *');

  return (
    <ReUnixCron
      value={value}
      onChange={setValue}/>
  );
};
export default App;`;
