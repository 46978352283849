export const i18n = {
  en: {
    title: 'Disabled state',
    desc1: 'Use',
    desc2: 'prop to control component\'s state.',
    disabled: 'Disabled',
    action: 'Toggle state'
  },
  ua: {
    title: 'Вимкнений стан',
    desc1: 'Використовуй',
    desc2: 'prop щоб контролювати стан компонента.',
    disabled: 'Вимкнено',
    action: 'Переключити стан'
  }
};
