import React, { Fragment } from 'react';
import { HashLink } from 'react-router-hash-link';

import { Meta } from './../../../../shared/meta';
import { LangProps } from './../../../../shared/lang-props.type';
import { HideTabsExample, hideTabsI18n } from './../../shared/hide-tabs';
import { TabsExample, tabsI18n } from './../../shared/tabs';
import { i18n } from './i18n';

export const CustomizationVisibilityProps = ({ lang }: LangProps) => {
  const localization = i18n[lang];
  const { title: hideTabsTitle } = hideTabsI18n[lang];
  const { title: tabsTitle } = tabsI18n[lang];

  return (
    <Fragment>
      <Meta
        lang={lang}
        url="/doc/customization/visibility-props"
        title={localization.title}/>

      <div className="content-section border-bottom">
        <h1 className="doc-title">{localization.title}</h1>
        <p>
          {localization.desc}
        </p>

        <ul>
          <li>
            <HashLink smooth to="#tabs-managing">
              {tabsTitle}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#tabs-visibility">
              {hideTabsTitle}
            </HashLink>
          </li>
        </ul>
      </div>

      <div
        id="tabs-managing"
        className="content-section border-bottom">

        <TabsExample lang={lang}/>
      </div>

      <div
        id="tabs-visibility"
        className="content-section">

        <HideTabsExample lang={lang}/>
      </div>
    </Fragment>
  );
};
