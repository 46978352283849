import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import './header.scss';

import { LangProps } from './../lang-props.type';
import { i18n } from './header.i18n';
import logo from './logo.png';
import UALogo from './ua.svg';
import UKLogo from './uk.svg';

type HeaderProps = {
  hideBar: boolean,
  hideNav?: boolean,
  barChanged?: () => void
} & LangProps;

export const Header = ({ hideBar, hideNav, barChanged, lang }: HeaderProps) => {
  let bar: JSX.Element|null = null;
  let nav: JSX.Element|null = null;
  let langs: JSX.Element|null = null;
  const location = useLocation();
  const localization = i18n[lang];

  let langUrl = location.pathname.split('/')
    .filter(segment => !!segment)
    .filter(segment => segment.trim() !== 'ua')
    .join('/');

  if (lang !== 'ua') {
    langUrl = `/ua/${langUrl}`;
  }
  langUrl = langUrl.replace(/\/$/, '');
  langUrl = langUrl.startsWith('/') ? langUrl : `/${langUrl}`;

  if (!hideBar && barChanged) {
    bar = (
      <button
        className="btn btn-link link bar-link py-0 px-4 d-flex align-items-center"
        type="button"
        aria-label="toggle sidebar"
        onClick={() => barChanged()}>

        <FontAwesomeIcon icon={faBars}/>
      </button>
    );
  }

  if (!hideNav) {
    nav = (
      <div className="d-none d-md-flex flex-fill pl-5">
        <Link
          className="link px-4 d-flex align-items-center"
          to="doc/get-started">

          {localization.getStarted}
        </Link>

        <Link
          className="link px-4 d-flex align-items-center"
          to="doc/usage-demo">

          {localization.demo}
        </Link>
      </div>
    );
  }

  if (lang === 'ua') {
    langs = (
      <Link
        className="link px-4 d-flex align-items-center"
        to={langUrl}>

        <img
          className="mr-2"
          width={30}
          height={15}
          src={UKLogo}
          alt="English"/>

        English
      </Link>
    );
  } else {
    langs = (
      <Link
        className="link px-4 d-flex align-items-center"
        to={langUrl}>

        <img
          className="mr-2"
          width={65}
          height={44}
          src={UALogo}
          alt="Українська мова"/>

        Українська
      </Link>
    );
  }

  return (
    <header className="header d-flex justify-content-between align-items-stretch">
      <div className="d-flex">
        {!!bar && bar}

        <div className="d-flex">
          <Link
            className={'logo-link d-flex align-items-center ' + (!!bar ? 'ml-md-3' : 'ml-3')}
            to={lang === 'ua' ? '/ua' : '/'}>

            <img
              src={logo}
              width={40}
              height={40}
              alt="React Cron">
            </img>

            React Cron
          </Link>
        </div>
      </div>

      {!!nav && nav}

      <div className="d-flex">
        {langs}

        <a
          className="ml-auto git-link link d-flex align-items-center px-3"
          target="_blank"
          rel="noreferrer"
          title="Github repository"
          href="https://github.com/ua-cron/react">

          <FontAwesomeIcon icon={faGithub}/>
        </a>
      </div>
    </header>
  );
};
