export const i18n = {
  en: {
    title: 'Quartz cron',
    desc: 'There is ReQuartzCron component to work with quartz cron format.',
    value: 'Cron Value'
  },
  ua: {
    title: 'Quartz формат',
    desc: 'Для роботи з quartz cron форматом використовуй ReQuartzCron компонент.',
    value: 'Cron результат'
  }
};
