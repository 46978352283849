export const i18n = {
  en: {
    title: 'Usage & Demo',
    styling: 'Styling'
  },
  ua: {
    title: 'Використання та демо',
    styling: 'Стилізація'
  }
};
