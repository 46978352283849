export const snippetScss =
`// styles.scss
$prefix: '.my';

#{$prefix}-row {
  overflow: hidden;

  #{$prefix}-col-1 {
    width: calc(100% / 12);
    float: left;
  }

  #{$prefix}-col-2 {
    width: calc(100% / 6);
    float: left;
  }
}

#{$prefix}-form-inline {
  display: flex;
  flex-direction: row;
}

#{$prefix}-form-control {
  margin: 0 .2rem;
}

#{$prefix}-form-check-label {
  padding-left: .4rem;
}`;

export const snippetTsx =
`// App.tsx
import React from 'react';
import { ReQuartzCron, ReUnixCron } from '@sbzen/re-cron';

import './styles.scss';

const App = () => (
  <ReQuartzCron cssClassPrefix="my-"/>
  // or <ReUnixCron cssClassPrefix="my-"/>
);
export default App;`;
