export const snippet =
`import React, { useState } from 'react';
import { ReQuartzCron } from '@sbzen/re-cron';

const App = () => {
  const [value, setValue] = useState('0 22 * * */3 * *');

  return (
    <ReQuartzCron
      value={value}
      onChange={setValue}/>
  );
};
export default App;`;
