export const i18n = {
  en: {
    title: 'Highlight elements',
    p1: 'Let\'s highlight "Specific second" section and options "1" and "20"'
  },
  ua: {
    title: 'Виділення єлементів',
    p1: 'Давай виділемо "Specific second" секцію та опції "1" та "20"'
  }
};
