import React, { useState, Fragment } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import oceanicNext from 'prism-react-renderer/themes/oceanicNext';

import { ReQuartzCron } from '@sbzen/re-cron';

import { LangProps } from './../../../../shared/lang-props.type';
import { Preview } from './../preview';
import { snippet } from './constants';
import { i18n } from './i18n';

export const DisabledStateExample = ({ lang }: LangProps) => {
  const [disabled, setDisabled] = useState(false);
  const value = '0,1,2 2/4 6/2 ? 2-7 SUN,MON 2019/1';
  const localization = i18n[lang];

  return (
    <Fragment>
      <h2 className="doc-subtitle mt-4">{localization.title}</h2>
      <p>
        {localization.desc1} <code>disabled</code> {localization.desc2}
      </p>

      <Preview lang={lang}>
        <div data-id="demo">
          <div className="border rounded p-2 mb-3">
            <b>{localization.disabled}: </b>
            <code>{disabled ? 'true' : 'false'}</code>
            <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={() => setDisabled(!disabled)}>
              {localization.action}
            </button>
          </div>

          <div className="demo">
            <ReQuartzCron
              value={value}
              disabled={disabled} />
          </div>
        </div>

        <div data-id="code">
          <Highlight
            {...defaultProps}
            theme={oceanicNext}
            code={snippet}
            language="tsx">

            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
                </div>
              ))}
              </pre>
            )}
          </Highlight>
        </div>
      </Preview>
    </Fragment>
  );
};
