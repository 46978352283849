import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Highlight, { defaultProps } from 'prism-react-renderer';
import oceanicNext from 'prism-react-renderer/themes/oceanicNext';

import {
  ValueProp,
  CssClassPrefixProp,
  DisabledProp,
  LocalizationProp,
  TabsProp,
  HideTabsProp,
  ActiveTabProp,
  OnChangeProp,
  OnTabChangeProp,
  RenderYearsFrom,
  RenderYearsTo
} from './props';

import { Meta } from './../../../shared/meta';
import { LangProps } from './../../../shared/lang-props.type';
import { tabExample, cronLocalizationExample } from './constants';
import { i18n } from './i18n';

import './styles.scss';

const reUnixCron = [
  ValueProp,
  CssClassPrefixProp,
  DisabledProp,
  LocalizationProp,
  TabsProp,
  HideTabsProp,
  ActiveTabProp,
  OnChangeProp,
  OnTabChangeProp
];

const reQuartzCron = [
  ...reUnixCron,
  RenderYearsFrom,
  RenderYearsTo
];

export const ApiReference = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="api-reference">
      <Meta
        lang={lang}
        url="/doc/api-reference"
        title={localization.title}/>

      <div className="content-section border-bottom">
        <h1 className="doc-title">
          {localization.title}
        </h1>

        <ul className="pt-2">
          <li>
            <HashLink smooth to="#re-quartz-cron">
              ReQuartzCron and ReCron
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#re-unix-cron">
              ReUnixCron
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#tab">
              Tab
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#cron-localization">
              CronLocalization
            </HashLink>
          </li>
        </ul>
      </div>

      <div id="re-quartz-cron">
        <h2 className="h4 m-0 content-section border-bottom">
          ReQuartzCron / ReCron
          <span className="badge badge-success ml-2">Component</span>
        </h2>

        <ul className="list-group list-group-flush">
          {reQuartzCron.map((Prop, i) => (
            <li
              className="list-group-item"
              key={i}>
              <Prop lang={lang}/>
            </li>
          ))}
        </ul>
      </div>

      <div id="re-unix-cron">
        <h2 className="h4 m-0 content-section border-bottom border-top">
          ReUnixCron
          <span className="badge badge-success ml-2">Component</span>
        </h2>

        <ul className="list-group list-group-flush">
          {reUnixCron.map((Prop, i) => (
            <li
              className="list-group-item"
              key={i}>
              <Prop lang={lang}/>
            </li>
          ))}
        </ul>
      </div>

      <div
        id="tab"
        className="content-section border-bottom border-top">

        <h2 className="h4 mb-3">
          Tab
          <span className="badge badge-info ml-2">Enum</span>
        </h2>

        <Highlight
          {...defaultProps}
          theme={oceanicNext}
          code={tabExample}
          language="tsx">

          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
              </div>
            ))}
            </pre>
          )}
        </Highlight>
      </div>

      <div
        id="cron-localization"
        className="content-section border-top">

        <h2 className="h4 mb-3">
          CronLocalization
          <span className="badge badge-info ml-2">Type</span>
        </h2>

        <Highlight
          {...defaultProps}
          theme={oceanicNext}
          code={cronLocalizationExample}
          language="tsx">

          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
              </div>
            ))}
            </pre>
          )}
        </Highlight>
      </div>
    </div>
  );
};
