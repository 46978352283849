import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import trackingHook from './../../shared/tracking-hook';
import { DeviceService } from './../../shared/device.service';
import { Header } from './../../shared/header/header';
import { Nav } from './../../shared/nav/nav';
import { LangProps } from './../../shared/lang-props.type';
import './doc.scss';

import ua from './../../shared/ua.svg';

export const Doc = ({ lang }: LangProps) => {
  trackingHook();

  const [showSidebar, setShowSidebar] = useState<boolean|null>(null);
  const sideBarClasses = `sidebar pl-0 pl-md-2 ${showSidebar || showSidebar === null ? 'md-show' : ''} ${showSidebar ? 'show' : ''}`;
  const contentClasses = `sidebar-bg position-absolute w-100 h-100 d-none d-md-none ${showSidebar ? 'd-block' : ''}`;

  const handleSidebar = () => {
    const isTablet = new DeviceService().isTablet();
    let state: boolean|null;
    if (!isTablet) {
      const close = showSidebar || showSidebar === null;
      state = close ? false : null;
    } else {
      state = !showSidebar;
    }
    setShowSidebar(state);
  };

  return (
    <div className="doc h-100">
      <div className="help">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.org/ukraine-relief/">

          <span>Support Ukraine</span>
          <img
            src={ua}
            width={26}
            height={26}
            alt="Ukraine"/>
          Help Provide Humanitarian Aid to Ukraine.
        </a>
      </div>

      <Header
        hideBar={false}
        hideNav={true}
        lang={lang}
        barChanged={handleSidebar}/>

      <div className="container-fluid p-0 content-height">
        <div className="d-flex h-100">
          <div className={sideBarClasses}>
            <Nav lang={lang}></Nav>
          </div>

          <div
            className={contentClasses}
            onClick={() => setShowSidebar(false)}>
          </div>

          <div className="col content mh-100">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
