import React, { useState, Fragment } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import oceanicNext from 'prism-react-renderer/themes/oceanicNext';

import { ReQuartzCron } from '@sbzen/re-cron';

import { LangProps } from './../../../../shared/lang-props.type';
import { Preview } from './../preview';
import { snippet } from './constants';
import { i18n } from './i18n';

export const HideTabsExample = ({ lang }: LangProps) => {
  const [tabsVisibility, setTabsVisibility] = useState(true);
  const localization = i18n[lang];

  return (
    <Fragment>
      <h2 className="doc-subtitle mt-4">{localization.title}</h2>
      <p>
        {localization.p1} <code>hideTabs</code> {localization.p2}
      </p>

      <Preview lang={lang}>
        <div data-id="demo">
          <div className="border rounded p-2 mb-3">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => setTabsVisibility(!tabsVisibility)}>
              {!tabsVisibility && localization.hide}
              {tabsVisibility && localization.show}
            </button>
          </div>

          <div className="demo">
            <ReQuartzCron hideTabs={tabsVisibility}/>
          </div>
        </div>

        <div data-id="code">
          <Highlight
            {...defaultProps}
            theme={oceanicNext}
            code={snippet}
            language="tsx">

            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
                </div>
              ))}
              </pre>
            )}
          </Highlight>
        </div>
      </Preview>
    </Fragment>
  );
};
