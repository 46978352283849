export const i18n = {
  en: {
    title: 'Vertical tabs',
    p1: 'Let\'s make a vertical tabs'
  },
  ua: {
    title: 'Вертикальні вкладки',
    p1: 'Давай зробимо вертикальні вкладки'
  }
};
