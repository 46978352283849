import React from 'react';
import { HashLink } from 'react-router-hash-link';


import { LangProps } from './../../../shared/lang-props.type';
import { i18n } from './i18n';

export const RenderYearsFrom = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="row">
      <div className="col-2">
        <code>renderYearsFrom</code>
      </div>
      <div className="col">
        {localization.renderYearsFrom.p}
        <br />
        <b>{localization.type}</b> <code>number</code>
        <br />
        <b>{localization.defaultValue}</b> <code>2019</code>
      </div>
    </div>
  );
};

export const RenderYearsTo = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="row">
      <div className="col-2">
        <code>renderYearsTo</code>
      </div>
      <div className="col">
        {localization.renderYearsTo.p}
        <br />
        <b>{localization.type}</b> <code>number</code>
        <br />
        <b>{localization.defaultValue}</b> <code>2098</code>
      </div>
    </div>
  );
};

export const ValueProp = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="row">
      <div className="col-2">
        <code>value</code>
      </div>
      <div className="col">
        {localization.value.p}
        <br />
        <b>{localization.type}</b> <code>string</code>
        <br />
        <b>{localization.defaultValue}</b> {localization.emptyString} <code>""</code>
      </div>
    </div>
  );
};

export const CssClassPrefixProp = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="row">
      <div className="col-2">
        <code>cssClassPrefix</code>
      </div>
      <div className="col">
        {localization.cssClassPrefix.p1} <code>cssClassPrefix="my-"</code> {localization.cssClassPrefix.p2} <code>form-group</code> {localization.cssClassPrefix.p3} <code>my-form-group</code>.
        <br />
        <b>{localization.type}</b> <code>string</code>
        <br />
        <b>{localization.defaultValue}</b> {localization.emptyString} <code>""</code>
      </div>
    </div>
  );
};

export const DisabledProp = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="row">
      <div className="col-2">
        <code>disabled</code>
      </div>
      <div className="col">
        {localization.disabled.p}
        <br />
        <b>{localization.type}</b> <code>boolean</code>
        <br />
        <b>{localization.defaultValue}</b> <code>false</code>
      </div>
    </div>
  );
};

export const LocalizationProp = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="row">
      <div className="col-2">
        <code>localization</code>
      </div>
      <div className="col">
        {localization.localization.p}
        <br />
        <b>{localization.type}</b> <HashLink smooth to="#cron-localization">CronLocalization</HashLink>
      </div>
    </div>
  );
};

export const TabsProp = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="row">
      <div className="col-2">
        <code>tabs</code>
      </div>
      <div className="col">
        {localization.tabs.p}
        <br/>
        <b>{localization.type}</b>
        <HashLink smooth to="#tab">Tab</HashLink>[]
      </div>
    </div>
  );
};

export const HideTabsProp = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="row">
      <div className="col-2">
        <code>hideTabs</code>
      </div>
      <div className="col">
        {localization.hideTabs.p}
        <br/>
        <b>{localization.type}</b> <code>boolean</code>
        <br/>
        <b>{localization.defaultValue}</b> <code>false</code>
      </div>
    </div>
  );
};

export const ActiveTabProp = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="row">
      <div className="col-2">
        <code>activeTab</code>
      </div>
      <div className="col">
        {localization.activeTab.p}
        <br/>
        <b>{localization.type}</b> <HashLink smooth to="#tab">Tab</HashLink>
      </div>
    </div>
  );
};

export const OnChangeProp = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="row">
      <div className="col-2">
        <code>onChange</code>
      </div>
      <div className="col">
        {localization.onChange.p1}
        <br />
        {localization.onChange.p2}
        <br />
        <b>{localization.type}</b> <code>(value: string) =&gt; void</code>
      </div>
    </div>
  );
};

export const OnTabChangeProp = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <div className="row">
      <div className="col-2">
        <code>onTabChange</code>
      </div>
      <div className="col">
        {localization.onTabChange.p1}
        <br />
        {localization.onTabChange.p2}
        <br />
        <b>{localization.type}</b> <code>(tab: <HashLink smooth to="#tab">Tab</HashLink>) =&gt; void</code>
      </div>
    </div>
  );
};
