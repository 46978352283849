import React from 'react';
import { Helmet } from 'react-helmet';

import { LangProps } from './lang-props.type';

const domain = 'https://recron.emptyui.com';

type Props = {
  title: string,
  url: string,
  prependTitle?: boolean
} & LangProps;

export const Meta = ({ lang, prependTitle = true, title, url }: Props) => {
  title = prependTitle ? `${title} - React Cron` : title;
  const langCode = lang === 'ua' ? 'uk' : 'en';
  const canonical = `${domain}${lang === 'ua' ? '/' + lang : ''}${url}`;

  return (
    <Helmet htmlAttributes={{ lang: langCode }}>
      <title>{title}</title>
      <link rel="canonical" href={canonical}/>
    </Helmet>
  );
};
