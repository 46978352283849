export const i18n = {
  en: {
    title: 'Visibility props',
    desc: 'There are several props to control elements visibility:'
  },
  ua: {
    title: 'Параметри відображення',
    desc: 'Кілька props для керуванням відображення елементів:'
  }
};
