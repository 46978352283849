export const i18n = {
  en: {
    title: 'Tabs managing',
    p1: 'Use',
    p2: 'prop to pass tabs you want to show.',
    p3: 'The order of the tabs depends on the order of the tab list you specify.',
    availableQuartzValues: 'Available quartz values:',
    availableUnixValues: 'Available unix values:',
    visibleTabs: 'Visible tabs:'
  },
  ua: {
    title: 'Керування вкладками',
    p1: 'Використовуй',
    p2: 'prop щоб передати вкладки які хочеш відобразити.',
    p3: 'Порядок вкладок залежить від вказаного тобою порядку.',
    availableQuartzValues: 'Доступні quartz значення:',
    availableUnixValues: 'Доступні unix значення:',
    visibleTabs: 'Видимі вкладки:'
  }
};
