export const i18n = {
  en: {
    title: 'API reference',
    type: 'Type:',
    defaultValue: 'Default value:',
    emptyString: 'empty string',
    value: {
      p: 'The value will be used to prefill the cron controls.'
    },
    cssClassPrefix: {
      p1: 'The prefix will be used in css classes generating, for example you passed',
      p2: 'as a result the bootstrap class will be transformed from',
      p3: 'to'
    },
    disabled: {
      p: 'The disabled state.'
    },
    localization: {
      p: 'Localization object'
    },
    tabs: {
      p: 'List of visible tabs.'
    },
    hideTabs: {
      p: 'Control tabs visibility.'
    },
    activeTab: {
      p: 'The active tab.'
    },
    onChange: {
      p1: 'The callback is triggered when the user changes the cron value using the UI.',
      p2: 'Event payload is the string of the newly cron value.'
    },
    onTabChange: {
      p1: 'The callback is triggered when active tab is changed.',
      p2: 'Event payload is the new activated tab.'
    },
    renderYearsFrom: {
      p: 'The starting year in the range of years.'
    },
    renderYearsTo: {
      p: 'End year in a range of years.'
    }
  },
  ua: {
    title: 'API документація',
    type: 'Тип:',
    defaultValue: 'Замовчуванням:',
    emptyString: 'порожній рядок',
    value: {
      p: 'Значення використовується для попереднього заповнення елементів керування cron.'
    },
    cssClassPrefix: {
      p1: 'Префікс буде використовуватися для генерації css classes, наприклад ти передав',
      p2: 'як результат bootstrap class буде трансформований з',
      p3: 'в'
    },
    disabled: {
      p: 'Вимкнений стан.'
    },
    localization: {
      p: 'Об\'єкт локалізації'
    },
    tabs: {
      p: 'Список відображених вкладок.'
    },
    hideTabs: {
      p: 'Контроль видимості вкладок.'
    },
    activeTab: {
      p: 'Активна вкладка.'
    },
    onChange: {
      p1: 'Функція викликається коли користувач змінює значення cron за допомогою інтерфейсу.',
      p2: 'Параметр функції - новий cron вираз.'
    },
    onTabChange: {
      p1: 'Функція викликається коли користувач змінює активну вкладку за допомогою інтерфейсу.',
      p2: 'Параметр функції - нова активна вкладка.'
    },
    renderYearsFrom: {
      p: 'Початковий рік у діапазоні років.'
    },
    renderYearsTo: {
      p: 'Кінцевий рік у діапазоні років.'
    }
  }
};
