import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const snap = navigator.userAgent === 'ReactSnap';
const production = process.env.NODE_ENV === 'production';
const alpha = process.env.REACT_APP_ALPHA;

const trackingHook = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!snap && !alpha && production) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);
};

export default trackingHook;
