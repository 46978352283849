import React, { Fragment } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import oceanicNext from 'prism-react-renderer/themes/oceanicNext';

import { ReQuartzCron } from '@sbzen/re-cron';

import { Meta } from './../../../shared/meta';
import { LangProps } from './../../../shared/lang-props.type';
import { i18n } from './i18n';
import { example } from './constants';

export const GetStarted = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <Fragment>
      <Meta
        lang={lang}
        url="/doc/get-started"
        title={localization.getStarted}/>

      <h1 className="doc-title content-section pb-2">
        {localization.getStarted}
      </h1>

      <p className="content-section border-bottom">
        {localization.desc.p1}
        <br />
        {localization.desc.p2}{' '}
        <a
          target="blank"
          href="http://www.quartz-scheduler.org/documentation/quartz-2.3.0/tutorials/crontrigger.html">
          Quartz
        </a>
        {' '}{localization.desc.p3}
        {' '}{localization.desc.p4}
        <br />
        {localization.desc.p5}{' '}
        <a
          target="blank"
          href="https://www.freeformatter.com/cron-expression-generator-quartz.html">
          {localization.desc.p6}
        </a>
        {' '}{localization.desc.p7}
      </p>

      <div className="content-section border-bottom">
        <h2 className="doc-subtitle">{localization.installation.title}</h2>
        <p className="m-0">
          {localization.installation.desc}
          <br />
          <code>npm install --save @sbzen/re-cron</code>
        </p>
      </div>

      <div className="content-section">
        <h2 className="doc-subtitle">{localization.add.title}</h2>
        <p>
          {localization.add.desc}
        </p>

        <Highlight
          {...defaultProps}
          theme={oceanicNext}
          code={example}
          language="jsx">

          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
              </div>
            ))}
            </pre>
          )}
        </Highlight>

        <h2 className="doc-subtitle mt-4 pt-2">{localization.result.title}</h2>
        <p>
          {localization.result.desc}
        </p>

        <div className="demo">
          <ReQuartzCron/>
        </div>
      </div>
    </Fragment>
  );
};
