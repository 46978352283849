import React, { Fragment } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import oceanicNext from 'prism-react-renderer/themes/oceanicNext';

import { ReQuartzCron } from '@sbzen/re-cron';

import { LangProps } from './../../../../shared/lang-props.type';
import { Preview } from './../preview';
import { snippetTsx, snippetScss } from './constants';
import { i18n } from './i18n';

export const RedesignExample = ({ lang }: LangProps) => {
  const localization = i18n[lang];

  return (
    <Fragment>
      <h2 className="doc-subtitle mt-4">{localization.title}</h2>
      <p>
        {localization.p1}
        <br/>
        {localization.p2} <code>cssClassPrefix</code> {localization.p3}
        <br/>
        {localization.p4} <code>cssClassPrefix="my-"</code> {localization.p5} <code>form-group</code> {localization.p6} <code>my-form-group</code>.
      </p>

      <Preview lang={lang}>
        <div data-id="demo">
          <div className="demo">
            <ReQuartzCron cssClassPrefix="my-"/>
          </div>
        </div>

        <div data-id="code">
          <Highlight
            {...defaultProps}
            theme={oceanicNext}
            code={snippetScss}
            language="scss">

            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
                </div>
              ))}
              </pre>
            )}
          </Highlight>
          <Highlight
            {...defaultProps}
            theme={oceanicNext}
            code={snippetTsx}
            language="tsx">

            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
                </div>
              ))}
              </pre>
            )}
          </Highlight>
        </div>
      </Preview>
    </Fragment>
  );
};
